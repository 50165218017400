import HealthIssue from "@/models/health-issue.model";

export class BenefitsSelectionProps {
  constructor(
    public breedId?: number,
    public dietType?: string,
    public dateOfBirth?: string,
    public gender?: string,
    public practiceId?: number,
    public healthIssues: Array<HealthIssue> = []
  ) {}

  public isFilled() {
    if (this.breedId && this.dietType && this.dateOfBirth && this.gender && this.practiceId) {
      return true;
    }
    return false;
  }
}
