import axios from "axios";
import { PartialUpdatePetRequest, UpdatePetRequest, UpdatePetResponse } from "@/api/api.dto";

export class PetService {
  private readonly baseUrl = "/api/vets/v1/pet";
  private readonly prototypeUrl = "/api/vets/prototype";

  public getCurrentLifeState(dob: Date, breedId: number): Promise<string> {
    return axios
      .get(`${this.baseUrl}/current-life-stage`, {
        params: { dob: dob, breed: breedId }
      })
      .then(response => response.data.resp.life_stage);
  }

  public createNewPet(payload: UpdatePetRequest): Promise<UpdatePetResponse> {
    return axios.post(this.baseUrl, payload).then(response => response.data.resp);
  }
  public deletePet(petId: number): Promise<any> {
    return axios.delete(`${this.baseUrl}/${petId}`).then(response => response.status === 200);
  }
  public partialUpdatePet(
    petId: number,
    payload: PartialUpdatePetRequest
  ): Promise<UpdatePetResponse> {
    return axios
      .patch(`${this.baseUrl}/partial-update/${petId}`, payload)
      .then(response => response.data.resp);
  }
  public partialCreatePet(payload: PartialUpdatePetRequest) {
    return axios
      .post(`${this.baseUrl}/partial-update`, payload)
      .then(response => response.data.resp);
  }
  public shouldAlgorithmVersionChange(petId: number): Promise<boolean> {
    // returns true if a pet has 'legacy' algorithm version
    return axios
      .get(`${this.baseUrl}/${petId}/should-algorithm-version-change`)
      .then(response => response.data.resp.should_algorithm_version_change);
  }

  public updateConditionRecord(petId: number, weight: number, bcs: number) {
    return axios
      .put(`${this.prototypeUrl}/pet/${petId}/update-weight/`, { weight: weight, bcs: bcs })
      .then(response => response.data.resp);
  }
}

const petService = new PetService();
export default petService;
