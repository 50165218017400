import Address from "@/models/address.model";
import Pet from "@/models/pet.v2.model";

export class CustomerType {
  customerTypeId: number;
  name: string;

  constructor(init?: Partial<CustomerType>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        customerTypeId: rawData.customer_type_id,
        name: rawData.name
      });
    }
  }
}

export default class Customer {
  customerId: number;
  first: string;
  last: string;
  email: string;
  pets: Pet[];
  customerTypes?: CustomerType[];
  addresses?: Address[];
  mobilePhone: string;
  homePhone: string;
  status: string;
  created: Date;
  orders: number;
  contactConsent: boolean;
  practiceName: string;

  public constructor(init?: Partial<Customer>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        customerId: rawData.customer_id,
        name: rawData.name,
        first: rawData.first,
        last: rawData.last,
        email: rawData.email,
        customerTypes: rawData.customer_types?.map(
          (rawPartialType: any) => new CustomerType({}, rawPartialType)
        ),
        pets: rawData.pets.map((rawPartialPet: any) => new Pet({}, rawPartialPet)),
        addresses: rawData.addresses?.map((rawAddress: any) => new Address({}, rawAddress)),
        mobilePhone: rawData.mobile_phone,
        homePhone: rawData.home_phone,
        status: rawData.status,
        contactConsent: rawData.contact_consent
      });
    }
  }

  public getFullName(): string | undefined {
    if (!this.first || !this.last) {
      return undefined;
    }
    return `${this.first} ${this.last}`;
  }

  public getPhoneNumbers(): string {
    let phones = [this.homePhone, this.mobilePhone];
    phones = phones.filter(p => !!p);
    return phones.join(", ");
  }

  public getPetNames(): string {
    return this.pets ? this.pets.map(p => p.name).join(", ") : "";
  }

  get customerTypesDisplay(): string {
    return this.customerTypes ? this.customerTypes.map(type => type.name).join(", ") : "";
  }

  public getMarketingConsent(): string {
    return this.contactConsent ? "Yes" : "No";
  }

  get shippingAddress(): Address | undefined {
    const address = this.addresses?.filter(addr => !!addr.shipping)[0];
    return address || undefined;
  }

  get billingAddress(): Address | undefined {
    const address = this.addresses?.filter(addr => !addr.shipping)[0];
    return address || undefined;
  }

  get isComplete(): boolean {
    return this.status.toLowerCase() === "complete";
  }
}

export interface CustomerUpdateDTO {
  first: string;
  last: string;
  email: string;
  mobile_phone: string;
  contact_consent: boolean;
}
