<template>
  <div>
    <v-progress-circular
      :class="center ? 'center' : ''"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div
      v-if="loadingText"
      class="loading-text"
      :style="`text-align: ${center ? 'center' : 'inherit'}`"
    >
      {{ loadingText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingCircle",
  props: {
    center: Boolean,
    loadingText: String
  }
};
</script>

<style scoped>
.center {
  width: 100% !important;
}

.loading-text {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
</style>
