
import Vue from "vue";
import { AuthActionsNames } from "@/store/auth/auth.actions";
import { hasAccess } from "@/utils/has-access.function";
import Cookies from "js-cookie";
import { ZendeskCookieID } from "@/services/zendesk.service";

export default Vue.extend<any, any, any, any>({
  name: "WithAppBar",
  inject: ["Api"],
  props: {
    title: {
      type: String,
      default: "PRO PLAN Personalised Nutrition"
    }
  },
  methods: {
    async logout() {
      try {
        await this.Api.vetLogout();
        this.$store.dispatch(AuthActionsNames.VET_LOGOUT);
        this.dispatchGTMEventLogout();
        this.$router.push("/login");
      } catch (error) {
        // handle error
      }
    },
    dispatchGTMEventLogout() {
      this.$gtm.trackEvent({
        event: "vet_logout"
      });
    },
    hasAccess
  },
  computed: {
    vetPracticeGroupName() {
      return this.$store.getters.vetPracticeGroupName;
    },
    vetUsername() {
      return this.$store.getters.vetName;
    },
    zendeskLink() {
      const zendeskJwtToken = Cookies.get(ZendeskCookieID);
      return `https://personalisedproplan.zendesk.com/hc?jwt=${zendeskJwtToken}`;
    }
  }
});
