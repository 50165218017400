export enum SubscriptionStatus {
  // keep it as it is for reverse mapping: https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
  PENDING = "pending",
  COMPLETE = "complete",
  ACTIVE = "active",
  CANCELLED = "cancelled",
  PAUSED = "paused"
}

export default class Subscription {
  subscriptionId?: number;
  customerId: number;
  start: Date;
  end: Date;
  status: SubscriptionStatus;
  targetKcalPercent: number;
  scheduledNextDeliveryDate: Date;
  fulfilmentDate: Date;

  public constructor(init?: Partial<Subscription>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        subscriptionId: rawData.subscription_id,
        customerId: rawData.customer_id,
        start: new Date(rawData.start * 1000),
        end: rawData.end ? new Date(rawData.end * 1000) : null,
        status: SubscriptionStatus[rawData.status.toUpperCase() as keyof typeof SubscriptionStatus],
        targetKcalPercent: rawData.target_kcal_percent,
        scheduledNextDeliveryDate: rawData.scheduled_next_delivery_date
          ? new Date(rawData.scheduled_next_delivery_date)
          : null,
        scheduledNextBillingDatetime: rawData.scheduled_next_billing_datetime
          ? new Date(rawData.scheduled_next_billing_datetime)
          : null,
        fulfilmentDate: rawData.fulfilment_date ? new Date(rawData.fulfilment_date) : null
      });
    }
  }

  get active(): boolean {
    return this.status === SubscriptionStatus.ACTIVE;
  }

  get statusDisplay(): string {
    switch (this.status?.toLowerCase()) {
      case SubscriptionStatus.PENDING:
        return "Incomplete";
      case SubscriptionStatus.COMPLETE:
        return "Needs checkout";
      case SubscriptionStatus.ACTIVE:
        return "Active";
      case SubscriptionStatus.CANCELLED:
        return "Cancelled";
      case SubscriptionStatus.PAUSED:
        return "Paused";
      default:
        return "";
    }
  }
}
