export default class Breed {
  breedId?: number;
  name?: string;

  public constructor(init?: Partial<Breed>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        breedId: rawData.breed_id,
        name: rawData.name
      });
    }
  }
}
