
import Vue from "vue";
import practiceService from "@/services/practiceService";
import WithAppBar from "@/layouts/WithAppBar.vue";
import MaintenanceSelection from "@/components/v2/BlendConfiguration/MaintenanceSelection.vue";
import TherapeuticSelection from "@/components/v2/BlendConfiguration/TherapeuticSelection.vue";
import { BenefitsSelectionProps } from "@/props/BenefitsSelectionProps";
import Information, { InformationProps } from "@/components/v2/BlendConfiguration/Information.vue";
import DatePicker from "@/components/v2/DatePicker.vue";
import blendConfigurationService, {
  ActivityLevelDTO,
  BlendConfigurationDto,
  BlendConfigurationUpdate,
  SelectableData
} from "@/services/blendConfiguration.service";
import { validators } from "@/validators";
import petService from "@/services/pet.service";
import { PartialUpdatePetRequest } from "@/api/api.dto";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

type ActivityLevelDisplay = ActivityLevelDTO & { disabled: boolean };

type PracticeDropdown = {
  value: number;
  text: string;
};

export default Vue.extend<any, any, any, any>({
  name: "PetDetails",
  components: {
    BreadCrumbs,
    WithAppBar,
    MaintenanceSelection,
    TherapeuticSelection,
    DatePicker,
    Information,
    LoadingCircle
  },
  inject: ["Api"],
  data() {
    return {
      petId: Number,
      customerId: Number,
      petDtoWatch: ["breedId", "dietType", "dateOfBirth", "gender"],
      informationsPropsWatch: ["practiceId"],
      practiceDropdownItems: [] as Array<PracticeDropdown>,
      benefitsSelectionError: false,
      petNameRules: [validators.required("Pet name")],
      breedRules: [validators.required("Breed")],
      dobRules: [validators.required("Date of birth")],
      pleaseSelectRules: [(v: any) => (v === null || v === undefined ? `Please select.` : true)],
      weightRule: (v?: number) => {
        if (v === undefined || v < 0.5) return "The minimum weight is 0.5 kg";
        return true;
      },
      petDto: new BlendConfigurationDto(),
      benefitsSelectionProps: new BenefitsSelectionProps(),
      informationProps: new InformationProps(),
      formRemoteData: {
        breeds: [],
        crossBreeds: [],
        activityLevels: [],
        minPetAgeDays: 0
      } as SelectableData,
      breedsDataSource: [] as any[],
      bodyConditionSource: [],
      activityLevelsSource: [],
      submitEnabled: false,
      saving: false,
      forceDietReselection: false
    };
  },
  computed: {
    maxDateOfBirth() {
      const date = new Date();
      date.setDate(date.getDate() - this.formRemoteData.minPetAgeDays);
      return date;
    },
    bodyConditionDescription() {
      const condition = this.bodyConditionSource.find(
        (x: any) => x.identifier === this.petDto.bodyConditionIdentifier
      );
      return condition?.description;
    }
  },
  watch: {
    "petDto.breedId"() {
      this.refreshActivityLevels();
    },
    "petDto.dateOfBirth"() {
      this.refreshActivityLevels();
    }
  },
  methods: {
    async loadPetData() {
      if (!this.petId) {
        return;
      }
      const petDto = await blendConfigurationService.get(this.customerId, this.petId);
      this.petDto = petDto;

      this.benefitsSelectionProps = new BenefitsSelectionProps(
        petDto.breedId,
        this.forceDietReselection ? undefined : petDto.dietType,
        petDto.dateOfBirth,
        petDto.gender,
        petDto.vetPracticeId,
        petDto.healthIssues
      );
      this.informationProps.petTypeId = petDto.petTypeId;
      this.informationProps.practiceId = petDto.vetPracticeId;
    },

    addWatchers() {
      this.petDtoWatch.forEach((item: string) => {
        this.$watch(`petDto.${item}`, () => this.watchForBenefitsSelectionProps(item));
      });

      this.informationsPropsWatch.forEach((item: string) => {
        this.$watch(`informationProps.${item}`, () => this.watchForInformationProps(item));
      });
    },
    async refreshPetTypes() {
      this.petTypes = await practiceService.getPetTypesAvailableForPractice(
        this.petFormData.vetPracticeId
      );
    },
    async refreshActivityLevels() {
      const lifeStage = await this.getCurrentLifeStage();

      if (lifeStage === "puppy") {
        this.activityLevelsSource.find(
          (e: ActivityLevelDisplay) => e.identifier === "highly-active"
        ).disabled = true;

        if (this.petDto.activityLevelIdentifier === "highly-active") {
          this.petDto.activityLevelIdentifier = undefined;
        }
      } else {
        this.activityLevelsSource.find(
          (e: ActivityLevelDisplay) => e.identifier === "highly-active"
        ).disabled = false;
      }
    },

    async loadPractices() {
      const practices = await practiceService.getAvailablePractices();
      this.practiceDropdownItems = practices.map(practice => {
        return { value: practice.practiceId, text: practice.name };
      });
      if (this.practiceDropdownItems.length === 1) {
        const practiceId = this.practiceDropdownItems[0].value;
        this.informationProps.practiceId = practiceId;
        this.benefitsSelectionProps.practiceId = practiceId;
      }
    },
    async getCurrentLifeStage(): Promise<string | null> {
      const dob = this.petDto.dateOfBirth;
      const breedId = this.petDto.breedId;
      if (!dob || !breedId) {
        return null;
      }
      if (dob > this.maxDateOfBirth) {
        return "puppy";
      }

      return petService.getCurrentLifeState(dob, breedId);
    },

    async submitForm() {
      const validated = this.$refs.form.validate();
      this.benefitsSelectionError = this.benefitsSelectionProps.healthIssues.length < 1;
      if (!validated || this.benefitsSelectionError) {
        return;
      }
      this.saving = true;

      const payload = new BlendConfigurationUpdate(
        {},
        {
          name: this.petDto.name,
          breed: this.petDto.breedId,
          date_of_birth: this.petDto.dateOfBirth,
          gender: this.petDto.gender,
          is_neutered: this.petDto.neutered,
          weight: this.petDto.weight,
          condition: this.petDto.bodyConditionIdentifier,
          activity_level: this.petDto.activityLevelIdentifier,
          customer_id: this.customerId,
          vet_practice_id: this.informationProps.practiceId,
          pet_type_id: this.informationProps.petTypeId,
          health_issues: {
            diet_type: this.benefitsSelectionProps.dietType,
            primary_health_issue_id: this.benefitsSelectionProps.healthIssues[0].healthIssueId
          }
        }
      );
      if (this.benefitsSelectionProps.healthIssues[1]?.healthIssueId) {
        payload.health_issues.secondary_health_issue_id = this.benefitsSelectionProps.healthIssues[1].healthIssueId;
      }
      if (this.benefitsSelectionProps.healthIssues[2]?.healthIssueId) {
        payload.health_issues.tertiary_health_issue_id = this.benefitsSelectionProps.healthIssues[2].healthIssueId;
      }

      let petId = this.petId;
      if (this.petId) {
        await blendConfigurationService.put(this.customerId, this.petId, payload);
      } else {
        petId = await blendConfigurationService.post(this.customerId, payload);
        await this.$router.replace({
          name: this.prototypeRouteNames.BLEND_CONFIGURATION,
          params: { pet_id: petId, customer_id: this.customerId }
        });
      }
      await this.$router.push({
        name: this.routeNames.NUTRITIONAL_PLAN,
        params: { pet_id: petId, customer_id: this.customerId }
      });
    },

    clearHealthIssues() {
      this.benefitsSelectionProps.healthIssues = [];
    },

    async saveForLater() {
      const formNameValid = this.$refs.formName.validate(true);
      const formPracticeValid = this.$refs.formInformations.validatePractice();
      if (!formNameValid || !formPracticeValid) {
        return;
      }
      this.saving = true;

      const payload = {
        activity_level: this.petDto.activityLevelIdentifier,
        breed: this.petDto.breedId,
        condition: this.petDto.bodyConditionIdentifier,
        customer_id: this.customerId,
        date_of_birth: this.petDto.dateOfBirth || null,
        gender: this.petDto.gender,
        is_neutered: this.petDto.neutered,
        name: this.petDto.name,
        vet_practice_id: this.informationProps.practiceId,
        pet_type_id: this.informationProps.petTypeId,
        weight: this.petDto.weight
      } as PartialUpdatePetRequest;

      if (this.benefitsSelectionProps.healthIssues[0]?.healthIssueId) {
        payload.health_issues = {
          diet_type: this.petDto.dietType,
          primary_health_issue_id: this.benefitsSelectionProps.healthIssues[0].healthIssueId,
          secondary_health_issue_id: this.benefitsSelectionProps.healthIssues[1]?.healthIssueId,
          tertiary_health_issue_id: this.benefitsSelectionProps.healthIssues[2]?.healthIssueId
        };
      }

      let petId = this.petId;
      if (!petId) {
        const result = await petService.partialCreatePet(payload);
        petId = result.pet_id;

        await this.$router.replace({
          name: this.prototypeRouteNames.BLEND_CONFIGURATION,
          params: { pet_id: petId, customer_id: this.customerId }
        });
      } else {
        await petService.partialUpdatePet(petId, payload);
      }

      await this.$router.push({
        name: this.routeNames.CUSTOMER_HUB,
        params: { customer_id: this.customerId }
      });
    },
    async loadFormRemoteData() {
      this.formRemoteData = await blendConfigurationService.getSelectableData();
      this.bodyConditionSource = await this.Api.getAllPetBodyConditions();
      this.activityLevelsSource = this.formRemoteData.activityLevels.map(
        (item: ActivityLevelDTO) => {
          return { ...item, disabled: false } as ActivityLevelDisplay;
        }
      );
      this.bodyConditionSource = this.bodyConditionSource.conditions;
      this.breedsDataSource = [
        { header: "Crossbreeds", divider: true },
        ...this.formRemoteData.crossBreeds,
        { header: "Breeds", divider: true },
        ...this.formRemoteData.breeds
      ];
    },
    watchForBenefitsSelectionProps(item: string) {
      this.benefitsSelectionProps[item] = this.petDto[item];
      this.benefitsSelectionProps.healthIssues = [];
      this.benefitsSelectionError = false;
    },
    watchForInformationProps(item: string) {
      this.benefitsSelectionProps[item] = this.informationProps[item];
      this.benefitsSelectionProps.healthIssues = [];
      this.benefitsSelectionError = false;
    }
  },

  async mounted() {
    this.petId = +this.$route.params.pet_id;
    this.customerId = +this.$route.params.customer_id;
    if (this.petId) {
      this.forceDietReselection = await petService.shouldAlgorithmVersionChange(this.petId);
    }

    await Promise.all([this.loadPractices(), this.loadFormRemoteData(), this.loadPetData()]);
    this.addWatchers();
  }
});
