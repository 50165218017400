<template>
  <v-breadcrumbs :items="this.$route.meta.breadcrumbs(this.$route)" />
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BreadCrumbs"
});
</script>

<style scoped></style>
