import axios from "axios";
import { Practice } from "@/models/practice.model";
import { CustomerType } from "@/models/customer.model";

export class PracticeService {
  public baseApi = "/api/vets/v1";

  public getAvailablePractices(): Promise<Array<Practice>> {
    return axios.get(`${this.baseApi}/practice-groups/practices/`).then(practiceResp => {
      return practiceResp.data.resp.map((obj: any) => new Practice({}, obj));
    });
  }

  public async getPetTypesAvailableForPractice(practiceId: number): Promise<Array<CustomerType>> {
    const response = await axios.get(`${this.baseApi}/practice/${practiceId}/pet-types/`);
    return response.data.resp.map((obj: any) => new CustomerType({}, obj));
  }
}

const practiceService = new PracticeService();
export default practiceService;
