export class Practice {
  practiceId: number;
  name: string;

  constructor(init?: Partial<Practice>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        practiceId: rawData.practice_id,
        name: rawData.name
      });
    }
  }
}
