import ActivityLevel from "@/models/activity-level.model";
import Breed from "@/models/breed.model";
import ConditionRecord from "@/models/condition-record.model";
import HealthIssue from "@/models/health-issue.model";
import Subscription from "@/models/subscription.model";

export default class Pet {
  petId?: number;
  weight: number;
  age: number; // in days
  name: string;
  gender: string;
  dietType: string;
  reproductiveStatus: string;
  conditionRecord: ConditionRecord;
  healthIssues?: Array<HealthIssue>;
  breeds: Array<Breed>;
  activityLevel: ActivityLevel;
  currentSubscription?: Subscription;
  dob?: string;
  ageAsYMD?: {
    years: number;
    months: number;
    days: number;
  };
  showUpdateComponentOnDashboard: boolean;

  public constructor(init?: Partial<Pet>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        petId: rawData?.pet_id,
        weight: rawData?.weight,
        age: rawData?.age,
        name: rawData?.name,
        gender: rawData?.gender,
        dietType: rawData?.diet_type,
        reproductiveStatus: rawData?.reproductive_status,
        conditionRecord: new ConditionRecord({}, rawData?.condition_record),
        healthIssues: rawData?.health_issues?.map(
          (rawHealthIssue: any) => new HealthIssue({}, rawHealthIssue)
        ),
        breeds: rawData?.breeds?.map((rawBreed: any) => new Breed({}, rawBreed)),
        activityLevel: new ActivityLevel({}, rawData?.activity_level),
        currentSubscription: new Subscription({}, rawData.latest_subscription),
        dob: rawData?.dob,
        ageAsYMD: rawData?.age_as_ymd,
        showUpdateComponentOnDashboard: false
      });
    }
  }

  public getBirthDate(): string {
    if (!this.dob) {
      return "n/a";
    }
    return new Date(this.dob).toLocaleDateString("en-GB");
  }

  get ageDisplay(): string {
    if (!this.ageAsYMD) {
      return "n/a";
    }
    let ageText = "";
    if (this.ageAsYMD.years === 0 && this.ageAsYMD.months === 0) {
      return this.ageAsYMD.days === 1 ? "1 day" : `${this.ageAsYMD.days} days`;
    }
    if (this.ageAsYMD.years === 1) {
      ageText += "1 year";
    }
    if (this.ageAsYMD.years > 1) {
      ageText += `${this.ageAsYMD.years} years`;
    }
    if (this.ageAsYMD.months === 1) {
      ageText += ageText ? ", 1 month" : "1 month";
    }
    if (this.ageAsYMD.months > 1) {
      ageText += ageText ? `, ${this.ageAsYMD.months} months` : `${this.ageAsYMD.months} months`;
    }
    return ageText;
  }

  get ageFullDisplay(): string {
    if (!this.ageAsYMD || !this.dob) return "n/a";
    return `${this.getBirthDate()} (${this.ageDisplay})`;
  }

  namePossessiveForm(): string {
    return `${this.name}'s`;
  }

  getPrimaryHealthIssue(): string | undefined {
    return this.healthIssues?.[0].displayNameDefault;
  }

  getSecondaryHealthIssue(): string | undefined {
    return this.healthIssues?.[1].displayNameDefault;
  }

  getTertiaryHealthIssue(): string | undefined {
    return this.healthIssues?.[2].displayNameDefault;
  }

  get breedsDisplay(): string {
    if (this.breeds.length === 0) {
      return "n/a";
    }
    return this.breeds.map(breed => breed.name).join(", ");
  }

  get dietTypeDisplay(): string {
    switch (this.dietType) {
      case "vet_maintenance":
        return "Maintenance";
      case "vet_therapeutic":
        return "Therapeutic";
      default:
        return "n/a";
    }
  }
}
