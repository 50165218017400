export function emailValidator(message = "Invalid e-mail.") {
  return (v: string) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(v) || message;
  };
}

export function requiredValidator(field = "This field") {
  return (v: any) => !!v || `${field} is required.`;
}

export function postalCodeValidator(message = "Invalid postcode") {
  return (v: string) => {
    const pattern = /^([A-Za-z]{1,2}?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;
    return pattern.test(v) || message;
  };
}

export const validators = {
  email: emailValidator,
  required: requiredValidator,
  postalCode: postalCodeValidator
};
