
import Vue, { PropType } from "vue";
import HealthIssue from "@/models/health-issue.model";
import healthIssueService, { GetHealthIssueDto } from "@/services/healthIssues.service";
import { BenefitsSelectionProps } from "@/props/BenefitsSelectionProps";
import SelectionError from "@/components/v2/BlendConfiguration/SelectionError.vue";

enum NextToSelect {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  NO_MORE
}

export default Vue.extend<any, any, any, any>({
  name: "MaintenanceSelection",
  components: { SelectionError },
  props: {
    displayError: Boolean,
    value: Object as PropType<BenefitsSelectionProps>,
    petName: String,
    forceReselection: Boolean
  },
  data() {
    return {
      dto: this.value,
      showError: false,
      showNoSelectableError: false,
      isAnimation: false,
      componentHeight: "220px",
      nextToSelect: NextToSelect.PRIMARY,
      toDisplayBenefits: [] as HealthIssue[],
      selectedBenefits: [] as HealthIssue[]
    };
  },
  computed: {
    benefitCountDisplay() {
      return this.nextToSelect < 3 ? `(${this.nextToSelect}/3)` : "";
    }
  },
  watch: {
    "toDisplayBenefits.length"() {
      if (this.toDisplayBenefits.length > 6) {
        this.componentHeight = `${70 * (this.toDisplayBenefits.length / 2)}px`;
      } else {
        this.componentHeight = "220px";
      }
    },
    displayError() {
      this.showError = this.displayError;
    },
    value: {
      deep: true,
      immediate: true,
      async handler() {
        this.dto = this.value;
        if (!this.dto.isFilled()) {
          return;
        }
        await this.refreshSelectedHealthIssue();
      }
    }
  },
  methods: {
    async refreshSelectedHealthIssue() {
      let nextToSelect = NextToSelect.PRIMARY;
      const selectedBenefits = [];
      for (let i = 0; i < 3; i += 1) {
        if (this.dto.healthIssues[i]) {
          selectedBenefits.push(this.dto.healthIssues[i]);
          nextToSelect += 1;
        } else {
          selectedBenefits.push(HealthIssue.empty());
        }
      }
      this.selectedBenefits = selectedBenefits;
      this.nextToSelect = nextToSelect;
      await this.refreshSelectableHealthIssues();
    },
    async getSelectableHealthIssues(): Promise<HealthIssue[]> {
      this.showNoSelectableError = false;
      const params = new GetHealthIssueDto(
        this.dto.breedId,
        this.dto.dietType,
        this.dto.dateOfBirth,
        this.dto.gender,
        this.dto.practiceId
      );

      if (this.nextToSelect === NextToSelect.SECONDARY) {
        params.primary_health_issue_id = this.selectedBenefits[NextToSelect.PRIMARY].healthIssueId;
      } else if (this.nextToSelect === NextToSelect.TERTIARY) {
        params.primary_health_issue_id = this.selectedBenefits[NextToSelect.PRIMARY].healthIssueId;
        params.secondary_health_issue_id = this.selectedBenefits[
          NextToSelect.SECONDARY
        ].healthIssueId;
      }
      return healthIssueService.get(params);
    },
    async selectBenefit(index: number) {
      if (this.isAnimation) {
        return;
      }
      this.showError = false;

      const currentSelection = this.nextToSelect;
      this.startAnimation(index, currentSelection);

      const selected = this.toDisplayBenefits[index];

      setTimeout(async () => {
        this.dto.healthIssues[currentSelection] = selected;
        this.nextToSelect += 1;
        await this.refreshSelectedHealthIssue();
        this.stopAnimation(index, currentSelection);
      }, 300);
    },
    async removeBenefit(index: number) {
      if (this.isAnimation) {
        return;
      }

      this.nextToSelect = index;
      const selectedBenefits: HealthIssue[] = [];
      this.dto.healthIssues.forEach((element: HealthIssue, i: number) => {
        if (i < index) {
          selectedBenefits.push(element);
        }
      });
      this.dto.healthIssues = selectedBenefits;
      if (!this.dto.healthIssues.length) {
        this.showError = true;
      }
      await this.refreshSelectedHealthIssue();
    },
    startAnimation(from: number, to: number) {
      const element = this.$refs.benefits[from];
      this.isAnimation = true;
      element.classList.add(`move_${from}_to_${to}`);
    },
    stopAnimation(from: number, to: number) {
      const element = this.$refs.benefits[from];
      this.isAnimation = false;
      if (element) {
        element.classList.remove(`move_${from}_to_${to}`);
      }
    },
    async refreshSelectableHealthIssues() {
      if (this.nextToSelect !== NextToSelect.NO_MORE) {
        const toDisplayBenefits = await this.getSelectableHealthIssues();
        if (this.nextToSelect !== NextToSelect.NO_MORE) {
          /* Check one more time if we still need that after fetching of data */
          this.toDisplayBenefits = toDisplayBenefits;
        }
      } else {
        this.toDisplayBenefits = [];
      }

      this.showNoSelectableError =
        this.toDisplayBenefits.length === 0 && !this.selectedBenefits[0].healthIssueId;
    }
  },
  beforeMount() {
    if (this.forceReselection && this.dto) {
      // Let's assume that pet has no healthIssues so the selectedBenefits won't be filled
      this.dto.healthIssues = [];
    }
  }
});
