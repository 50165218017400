
import Vue, { PropType } from "vue";
import HealthIssue from "@/models/health-issue.model";
import healthIssueService, { GetHealthIssueDto } from "@/services/healthIssues.service";
import { BenefitsSelectionProps } from "@/props/BenefitsSelectionProps";
import SelectionError from "@/components/v2/BlendConfiguration/SelectionError.vue";

export default Vue.extend<any, any, any, any>({
  name: "TherapeuticSelection",
  components: { SelectionError },
  props: {
    displayError: Boolean,
    value: Object as PropType<BenefitsSelectionProps>,
    petName: String,
    forceReselection: Boolean
  },
  data() {
    return {
      dto: this.value,
      showError: false,
      showNoSelectableError: false,
      toDisplayBenefits: [] as HealthIssue[],
      selectedBenefit: null as null | HealthIssue,
      followUpBenefit: null as null | HealthIssue,
      isFollowUpSelected: null as null | boolean
    };
  },
  async mounted() {
    if (this.dto.healthIssues[0]) {
      this.selectedBenefit = this.dto.healthIssues[0];
      const followUpBenefit = await this.getSelectableHealthIssues(
        this.selectedBenefit.healthIssueId
      );
      if (followUpBenefit.length > 0) {
        this.followUpBenefit = followUpBenefit[0];
      }

      if (this.dto.healthIssues[1]) {
        this.isFollowUpSelected = true;
      }
    }
    if (this.forceReselection) {
      this.selectedBenefit = null;
    }

    await this.loadSelectableBenefits();
    this.addWatchers();
  },
  watch: {
    displayError() {
      this.showError = this.displayError;
    }
  },
  methods: {
    addWatchers() {
      this.$watch("selectedBenefit", async () => {
        if (!this.selectedBenefit) {
          this.dto.healthIssues = [];
          this.isFollowUpSelected = null;
          this.followUpBenefit = null;
          this.showError = true;
          return;
        }
        this.showError = false;

        this.dto.healthIssues = [this.selectedBenefit];

        const followUpBenefit = await this.getSelectableHealthIssues(
          this.selectedBenefit.healthIssueId
        );
        if (followUpBenefit.length > 0) {
          this.followUpBenefit = followUpBenefit[0];
        } else {
          this.followUpBenefit = null;
        }

        this.isFollowUpSelected = false;
      });

      this.$watch("isFollowUpSelected", () => {
        if (this.isFollowUpSelected === true) {
          this.dto.healthIssues = [this.selectedBenefit, this.followUpBenefit];
        } else if (this.isFollowUpSelected === false) {
          this.dto.healthIssues = [this.selectedBenefit];
        }
      });

      ["breedId", "dietType", "dateOfBirth", "gender", "practiceId"].forEach(e => {
        this.$watch(`value.${e}`, async () => {
          this.selectedBenefit = null;
          await this.loadSelectableBenefits();
        });
      });
    },
    async loadSelectableBenefits() {
      if (!this.dto.isFilled()) {
        return;
      }

      const toDisplayBenefits = await this.getSelectableHealthIssues();
      this.toDisplayBenefits = toDisplayBenefits;

      if (toDisplayBenefits.length === 0 && !this.selectedBenefit) {
        this.showNoSelectableError = true;
      } else {
        this.showNoSelectableError = false;
      }

      if (this.selectedBenefit) {
        this.selectedBenefit = this.toDisplayBenefits.find(
          (e: HealthIssue) => e.healthIssueId === this.selectedBenefit.healthIssueId
        );
      }
    },
    async getSelectableHealthIssues(primary_health_issue_id?: number): Promise<HealthIssue[]> {
      const params = new GetHealthIssueDto(
        this.dto.breedId,
        this.dto.dietType,
        this.dto.dateOfBirth,
        this.dto.gender,
        this.dto.practiceId
      );
      if (primary_health_issue_id) {
        params.primary_health_issue_id = primary_health_issue_id;
      }

      const healthIssues = await healthIssueService.get(params);
      return healthIssues;
    }
  }
});
