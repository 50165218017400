
import Vue, { PropType } from "vue";
import practiceService from "@/services/practiceService";

export class InformationProps {
  constructor(public practiceId?: number, public petTypeId?: number) {}
}

export default Vue.extend<any, any, any, any>({
  name: "Information",
  props: {
    value: Object as PropType<InformationProps>,
    practices: Array
  },
  data() {
    return {
      selection: this.value,
      petTypes: [],
      pleaseSelectRules: [(v: any) => (v === null || v === undefined ? `Please select.` : true)],
      advantages: [
        "A nutrition plan personally tailored to your dog, created in partnership by you and your veterinary team.",
        "A recipe made of high-quality ingredients, with proven benefits including shiny coats, healthy joints, happy stomachs and more.",
        "Easy monthly subscription. Delivered directly to your front door. Never run out of food again.",
        "No hidden extras and you can move or cancel deliveries at any time."
      ]
    };
  },
  watch: {
    async "selection.practiceId"() {
      await this.refreshPetTypes();
    }
  },
  methods: {
    async refreshPetTypes() {
      if (!this.selection.practiceId) {
        return;
      }

      const petTypes = await practiceService.getPetTypesAvailableForPractice(
        this.selection.practiceId
      );

      this.petTypes = petTypes.map((type: any) => {
        return { value: type.customerTypeId, text: type.name };
      });

      if (this.petTypes.length === 1) {
        this.selection.petTypeId = this.petTypes[0].value;
        return;
      }

      if (this.selection.petTypeId) {
        const isStillAvailable = this.petTypes.find(
          (type: any) => type.value === this.selection.petTypeId
        );

        if (!isStillAvailable) {
          this.selection.petTypeId = null;
        }
      }
    },
    validatePractice() {
      return this.$refs.practice.validate(true);
    }
  }
});
