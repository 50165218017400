export default class ActivityLevel {
  activityLevelId?: number;
  identifier?: string;
  name?: string;

  public constructor(init?: Partial<ActivityLevel>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        activityLevelId: rawData.activity_level_id,
        identifier: rawData.identifier,
        name: rawData.name_default
      });
    }
  }

  public getName(): string {
    switch (this.identifier?.toLowerCase()) {
      case "leisurely":
        return "Less Active";
      case "typical":
        return "Maintenance";
      case "active":
        return "Semi-Active";
      case "highly-active":
        return "Performance";
      default:
        return "";
    }
  }
}
