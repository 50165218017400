export default class Address {
  addressId: number;
  firstLine: string;
  secondLine?: string;
  thirdLine?: string;
  city: string;
  county?: string;
  postalCode: string;
  shipping: boolean;

  public constructor(init?: Partial<Address>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        addressId: rawData.address_id,
        firstLine: rawData.first_line,
        secondLine: rawData.second_line,
        thirdLine: rawData.third_line,
        city: rawData.city,
        county: rawData.county,
        postalCode: rawData.postal_code,
        shipping: rawData.shipping
      });
    }
  }

  public getFullAddress(): string {
    const firstLine = [this.firstLine, this.secondLine, this.thirdLine].filter(a => !!a).join(" ");
    const secondLine = [this.city, this.county, this.postalCode].filter(a => !!a).join(" ");
    return `${firstLine}, ${secondLine}`;
  }
}

export interface AddressUpdateDTO {
  first_line: string;
  second_line?: string;
  city: string;
  county?: string;
  postal_code: string;
}

export interface AddressesUpdateDTO {
  delivery_address: AddressUpdateDTO;
  billing_address: AddressUpdateDTO;
}
