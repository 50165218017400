
import Vue from "vue";
import moment from "moment";

export default Vue.extend<any, any, any, any>({
  name: "DatePicker",
  props: {
    value: {
      type: String,
      default: null
    },
    rules: Array,
    label: String,
    minDate: Date,
    maxDate: Date,
    maxDateError: String,
    labelFontWeight: {
      type: Number,
      default: 400
    },
    dataCy: {
      type: String,
      default: "date-picker"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      date: this.value,
      errorMessages: [],
      dateFormatted: null
    };
  },
  watch: {
    date(newDate) {
      this.$emit("input", newDate);
    },
    value(newDate) {
      this.date = newDate;
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("input", newDate);
    }
  },
  methods: {
    formatDate(date: string | null) {
      if (date) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      return "";
    },
    asDatePickerFormat(date: Date): string | undefined {
      return date ? date.toISOString().substring(0, 10) : undefined;
    },
    onDateInputChanged(value: string) {
      if (!value) {
        this.date = "";
        return;
      }

      const dateObject = moment(value, "DD/MM/YYYY");
      if (dateObject.isValid()) {
        this.date = dateObject.format("YYYY-MM-DD");
        if (this.maxDate) {
          if (dateObject > this.maxDate) {
            this.errorMessages = [this.maxDateError];
          } else {
            this.errorMessages = [];
          }
        }
      }
    }
  }
});
