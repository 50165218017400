import axios from "axios";
import HealthIssue from "@/models/health-issue.model";

export class GetHealthIssueDto {
  constructor(
    public breed_id?: number,
    public diet_type?: string,
    public dob?: string,
    public gender?: string,
    public practice_id?: number,
    public primary_health_issue_id?: number,
    public secondary_health_issue_id?: number
  ) {}
}

export class HealthIssueService {
  private readonly baseUrl = "/api/vets/v1";

  public get(params: GetHealthIssueDto): Promise<HealthIssue[]> {
    return axios
      .get(`${this.baseUrl}/selectable-health-issues`, { params })
      .then(response => response.data.resp)
      .then(resp => resp.health_issues.map((issue: any) => new HealthIssue({}, issue)));
  }
}

const healthIssueService = new HealthIssueService();
export default healthIssueService;
