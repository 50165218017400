export default class HealthIssue {
  displayNameDefault?: string;
  descriptionDefault?: string;
  nameDefault?: string;
  healthIssueId?: number;

  public constructor(init?: Partial<HealthIssue>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        healthIssueId: rawData.health_issue_id,
        descriptionDefault: rawData.description_default,
        displayNameDefault: rawData.display_name_default,
        nameDefault: rawData.name_default
      });
    }
  }
  public static empty() {
    return new HealthIssue({}, {});
  }
}
