import axios from "axios";
import HealthIssue from "@/models/health-issue.model";

export class ActivityLevelDTO {
  constructor(
    public activityLevelId: number,
    public identifier: string,
    public nameDefault: string,
    public descriptionDefault: string
  ) {}

  static buildFromJson(json: Record<string, any>): ActivityLevelDTO {
    return new ActivityLevelDTO(
      json.activity_level_id,
      json.identifier,
      json.name_default,
      json.description_default
    );
  }
}

export class BreedDTO {
  constructor(public breedId: number, public name: string) {}

  static buildFromJson(json: Record<string, any>): BreedDTO {
    return new BreedDTO(json.breed_id, json.name);
  }
}

export interface SelectableData {
  activityLevels: Array<ActivityLevelDTO>;
  breeds: Array<BreedDTO>;
  crossBreeds: Array<BreedDTO>;
  minPetAgeDays: number;
}

export class BlendConfigurationDto {
  constructor(
    public name?: string,
    public breedId?: number,
    public dateOfBirth?: string,
    public dietType?: string,
    public gender?: string,
    public neutered?: boolean,
    public weight?: number,
    public bodyConditionIdentifier?: string,
    public activityLevelIdentifier?: string,
    public vetPracticeId?: number,
    public petTypeId?: number,
    public healthIssues: HealthIssue[] = []
  ) {}
}

export class BlendConfigurationUpdate {
  name: string;
  breed: number;
  date_of_birth: string;
  gender: string;
  is_neutered: boolean;
  weight: number;
  condition: string;
  activity_level: string;
  customer_id: number;
  vet_practice_id: number;
  pet_type_id: number;
  health_issues: {
    diet_type: string;
    primary_health_issue_id: number;
    secondary_health_issue_id?: number;
    tertiary_health_issue_id?: number;
  };

  public constructor(init?: Partial<BlendConfigurationUpdate>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        name: rawData.name,
        breed: rawData.breed,
        date_of_birth: rawData.date_of_birth,
        gender: rawData.gender,
        is_neutered: rawData.is_neutered,
        weight: rawData.weight,
        condition: rawData.condition,
        activity_level: rawData.activity_level,
        customer_id: rawData.customer_id,
        vet_practice_id: rawData.vet_practice_id,
        pet_type_id: rawData.pet_type_id,
        health_issues: rawData.health_issues
      });
    }
  }
}

export class BlendConfigurationService {
  private readonly baseUrl = "/api/vets/v1/customer";

  public async getSelectableData(): Promise<SelectableData> {
    const response = await axios.get(`${this.baseUrl}/pet/blend-configuration`);

    const activityLevels = response.data.resp.activity_levels.map((item: Record<string, any>) => {
      return ActivityLevelDTO.buildFromJson(item);
    });
    const breeds = response.data.resp.breeds.map((item: Record<string, any>) => {
      return BreedDTO.buildFromJson(item);
    });

    const crossBreeds = breeds.filter((breed: BreedDTO) =>
      breed.name.toLowerCase().includes("crossbreed")
    );

    return {
      activityLevels: activityLevels,
      breeds: breeds,
      crossBreeds: crossBreeds,
      minPetAgeDays: response.data.resp.min_pet_age_days
    };
  }

  public get(customerId: number, petId: number): Promise<BlendConfigurationDto> {
    return axios
      .get(`${this.baseUrl}/${customerId}/pet/${petId}/blend-configuration`)
      .then(response => response.data.resp)
      .then(
        resp =>
          new BlendConfigurationDto(
            resp.name,
            resp.breeds[0]?.breed_id,
            resp.dob || undefined,
            resp.diet_type || undefined,
            resp.gender || undefined,
            resp.neutered,
            resp.weight || undefined,
            resp.condition_record?.identifier,
            resp.activity_level?.identifier,
            resp.vet_practice_id || undefined,
            resp.pet_type_id || undefined,
            resp.health_issues.map((issue: any) => new HealthIssue({}, issue))
          )
      );
  }
  public put(
    customerId: number,
    petId: number,
    payload: BlendConfigurationUpdate
  ): Promise<number> {
    return axios
      .put(`${this.baseUrl}/${customerId}/pet/${petId}/blend-configuration`, payload)
      .then(response => response.status);
  }

  public post(customerId: number, payload: BlendConfigurationUpdate): Promise<number> {
    return axios
      .post(`${this.baseUrl}/${customerId}/pet/blend-configuration`, payload)
      .then(response => response.data.resp.pet_id);
  }
}

const blendConfigurationService = new BlendConfigurationService();
export default blendConfigurationService;
